import CardConfiguration from '@/components/CardConfiguration';
import CardStackComponent from '@/components/CardStackComponent';
import CookieBanner from '@/components/CookieBanner';
import Footer from '@/components/Footer';
import PhoneMockup from '@/components/PhoneMockup';
import Seo from '@/components/Seo';
import { Branche } from '@/utils/Card.types';
import { graphql, Link, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getFulfilledCard, getUnfulfilledCard } from '@/utils/Card.utils';
import {
  brancheAmountForScroll,
  goToRegisterProvider,
  goToRegisterStempler,
  handleLogoScroll,
} from '@/utils/Page.utils';
import BakeryIcon from '../assets/branche/bakery.svg';
import FunIcon from '../assets/branche/cinema.svg';
import DrinkIcon from '../assets/branche/drink.svg';
import GymIcon from '../assets/branche/gym.svg';
import HairIcon from '../assets/branche/hair.svg';
import IceIcon from '../assets/branche/ice.svg';
import SwimIcon from '../assets/branche/swim.svg';
import CheckIcon from '../assets/icons/check.svg';
import LoginIcon from '../assets/icons/login.svg';
import Logo from '../assets/icons/logo.svg';
import MenuIcon from '../assets/icons/menu.svg';

const DrinkLanding: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [showCookieBanner, toggleCookieBanner] = useState<boolean>(true);
  const [activeMenu, setActiveMenu] = useState<number>(0);
  const [showMobileMenu, toggleShowMobileMenu] = useState<boolean>(false);
  const [hackIndex, setHackIndex] = useState<number>(0);
  const cookieName: string = process.env.GATSBY_GOOGLE_COOKIE_NAME!;
  const [cookies, __] = useCookies([cookieName]);
  const scrollRef = useRef<HTMLDivElement>(null);

  // this is used to counter the behaviour that on mount the document
  // is not defined and the parallax does not trigger
  useEffect(() => {
    setTimeout(() => {
      setHackIndex((old) => old + 1);
    }, 1500);
    toggleCookieBanner(cookies[cookieName] !== 'true');
  }, []);

  //initially scrolls to correct icon
  useEffect(() => {
    if (!scrollRef.current) return;
    //amount of branches in scroll wrapper
    const screenWidth =
      scrollRef.current.scrollWidth - scrollRef.current.clientWidth ?? 0;
    const oneItem = screenWidth / brancheAmountForScroll;
    scrollRef.current.scrollTo({ left: oneItem - oneItem / 2 });
  }, [scrollRef]);

  return (
    <div className="content-wrapper">
      <Seo />
      {showCookieBanner && (
        <CookieBanner
          onDecline={() => toggleCookieBanner(false)}
          onAccept={() => toggleCookieBanner(false)}
        />
      )}
      <div className="background-curve-top" />
      <div className="header">
        <MenuIcon
          className="menu hide-on-big"
          onClick={() => toggleShowMobileMenu(true)}
        />
        <Link to="/" className="logo-with-name">
          <Logo />
          <div className="hide-on-small">{t('appName')}</div>
        </Link>
        {showMobileMenu && (
          <div
            className="clickaway"
            onClick={() => toggleShowMobileMenu(false)}
          />
        )}
        <div
          className={`menu-wrapper ${!showMobileMenu && 'disappear'}`}
          onClick={() => toggleShowMobileMenu(false)}
        >
          <a
            href="#stempl"
            className={`menu ${activeMenu === 0 && 'selected'}`}
            onClick={() => setActiveMenu(0)}
          >
            {t('appName')}
          </a>
          <a
            href="#configurate"
            className={`menu ${activeMenu === 1 && 'selected'}`}
            onClick={() => setActiveMenu(1)}
          >
            {t('menu.configuration')}
          </a>
          <a
            href="#prices"
            className={`menu ${activeMenu === 2 && 'selected'}`}
            onClick={() => setActiveMenu(2)}
          >
            {t('menu.prices')}
          </a>
          <span className="login-icon">
            {showMobileMenu && <p>{t('menu.login')}</p>}
            <LoginIcon onClick={() => navigate(process.env.GATSBY_APP_URL!)} />
          </span>
        </div>
      </div>
      <div id="stempl" className="stempl-wrapper">
        <div className="section-info hide-on-small">
          <div className="stempl-heading">{t('appName')}</div>
          <div>{t('stempl.rewardLoyalty')}</div>
          <a className="hide-on-small" href="#stempl-info">
            <button
              onClick={goToRegisterStempler}
              className="button-on-color margin-bottom"
            >
              {t('stempl.becomeStempler')}
            </button>
          </a>
          <button className="button-on-color" onClick={goToRegisterProvider}>
            {t('stempl.becomeProvider')}
          </button>
        </div>

        <div className="stempl-big-heading hide-on-big">{t('appName')}</div>
        <div className="stempl-heading hide-on-big">
          {t('stempl.rewardLoyalty')}
        </div>
        <CardStackComponent branche={Branche.DRINK} />
        <div className="hide-on-big">
          <button
            className="button-on-color margin-bottom"
            onClick={goToRegisterStempler}
          >
            {t('stempl.becomeStempler')}
          </button>
          <button className="button-on-color" onClick={goToRegisterProvider}>
            {t('stempl.becomeProvider')}
          </button>
        </div>
      </div>
      <div className="spacing" />
      <div className="introduction-wrapper" key={`hack-index-${hackIndex}`}>
        <PhoneMockup
          className="hide-on-small"
          cardColors={['#c292de', '#a2be46', '#eb576d']}
          mainCardContent={getFulfilledCard(
            t('drink.sampleProvider'),
            <DrinkIcon />,
          )}
        />
        <div className="section-info">
          <div className="section-heading">{t('drink.heading')}</div>
          <div className="section-leisure-subheading">
            {t('drink.subheading')}
          </div>
          <div className="section-heading">{t('introduction.heading')}</div>
          <div>{t('introduction.subheading')}</div>
          <div className="section-text">{t('introduction.text')}</div>
          <a href="#configurate">
            <button className="button-on-background">{t('configure')}</button>
          </a>
        </div>
        <PhoneMockup
          className="hide-on-big"
          cardColors={['#c292de', '#a2be46', '#eb576d']}
          mainCardContent={getFulfilledCard(
            t('drink.sampleProvider'),
            <DrinkIcon />,
          )}
        />
      </div>
      <div className="spacing" />
      <div className="reward-wrapper">
        <div className="background-blob" />
        <div className="section-info">
          <div className="section-subheading">{t('reward.subheading')}</div>
          <div>{t('reward.text')}</div>
          <a href="#configurate">
            <button className="button-on-color">{t('configure')}</button>
          </a>
        </div>
        <PhoneMockup
          cardColors={['#eda93d', '#c292de', '#a2be46']}
          mainCardContent={
            <>
              <div className="card-header">{t('drink.sampleProvider')}</div>
              <div>
                <div>{t('drink.fullCard')}</div>
                <div className="reward-text">{t('reward.reward')}</div>
              </div>
            </>
          }
          left
        />
      </div>
      <div className="spacing" />
      <div className="config-wrapper">
        <PhoneMockup
          className="hide-on-small"
          cardColors={['#4faddb', '#eda93d', '#c292de']}
          mainCardContent={getUnfulfilledCard(t('appName'))}
        />
        <div className="section-info">
          <div className="section-subheading">{t('config.subheading')}</div>
          <div className="config-step">
            <Logo className="config-workflow-logo" />
            {t('config.textLogo')}
          </div>
          <div className="config-step">
            <div className="config-color-rect" />
            {t('config.textColor')}
          </div>
          <div className="config-step">
            <DrinkIcon />
            {t('config.textIcon')}
          </div>
          <a href="#configurate">
            <button className="button-on-background">
              {t('menu.configuration')}
            </button>
          </a>
        </div>
        <PhoneMockup
          className="hide-on-big"
          cardColors={['#ffce00', '#eda93d', '#c292de']}
          mainCardContent={getUnfulfilledCard(t('appName'))}
        />
      </div>
      <div id="configurate" className="spacing" />
      <CardConfiguration />
      <div id="prices" className="spacing" />
      <div className="prices-wrapper">
        <div className="section-info">
          <div className="section-heading">{t('prices.heading')}</div>
          <div className="section-opportunity">
            <div className="section-subheading">
              {t('prices.subheadingOffer')}
            </div>
            <br />
            <div className="section-offer">
              <CheckIcon />
              <div className="section-offer--text">{t('prices.textOffer')}</div>
            </div>
            <br />
            <div className="section-offer">
              <CheckIcon />
              <div className="section-offer--text">
                {t('prices.textOffer2')}
              </div>
            </div>
            <br />
            <div className="section-offer">
              <CheckIcon />
              <div className="section-offer--text">
                {t('prices.textOffer3')}
              </div>
            </div>
            <br />
            <div className="section-offer">
              <CheckIcon />
              <div className="section-offer--text">
                {t('prices.textOffer4')}
              </div>
            </div>
            <br />
          </div>
        </div>
        <div className="section-info">
          <div className="price-wrapper">
            <div className="price-wrapper--price">{t('prices.price')}</div>
            <div>{t('prices.perMonth')}</div>
            <button className="button-on-color">{t('prices.brintItOn')}</button>
          </div>
        </div>
      </div>

      <div className="branches">
        <div className="branches--wrapper">
          <div className="branches--wrapper--subheading">{t('branches')}</div>
          <div
            className="branches--wrapper--content"
            ref={scrollRef}
            onScroll={(evt) => handleLogoScroll(scrollRef, evt)}
          >
            <div className="spacer" />
            <Link to="/drink" className="drink hoverMe">
              <div className="branches--icons-content">
                <div className="branches--container">
                  <div className="branches--container--icons">
                    <DrinkIcon className="branches--container--icons--icon" />
                  </div>
                  <div className="branches--container--title">
                    {t('drink.title')}
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/hair" className="hair">
              <div className="branches--icons-content">
                <div className="branches--container">
                  <div className="branches--container--icons">
                    <HairIcon className="branches--container--icons--icon" />
                  </div>
                  <div className="branches--container--title">
                    {t('hair.title')}
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/bakery" className="bakery">
              <div className="branches--icons-content">
                <div className="branches--container">
                  <div className="branches--container--icons">
                    <BakeryIcon className="branches--container--icons--icon" />
                  </div>
                  <div className="branches--container--title">
                    {t('bakery.title')}
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/gym" className="gym">
              <div className="branches--icons-content">
                <div className="branches--container">
                  <div className="branches--container--icons">
                    <GymIcon className="branches--container--icons--icon" />
                  </div>
                  <div className="branches--container--title">
                    {t('gym.title')}
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/swim" className="swim">
              <div className="branches--icons-content">
                <div className="branches--container">
                  <div className="branches--container--icons">
                    <SwimIcon className="branches--container--icons--icon" />
                  </div>
                  <div className="branches--container--title">
                    {t('swim.title')}
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/fun" className="fun">
              <div className="branches--icons-content">
                <div className="branches--container">
                  <div className="branches--container--icons">
                    <FunIcon className="branches--container--icons--icon" />
                  </div>
                  <div className="branches--container--title">
                    {t('cinema.title')}
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/ice" className="ice">
              <div className="branches--icons-content">
                <div className="branches--container">
                  <div className="branches--container--icons">
                    <IceIcon className="branches--container--icons--icon" />
                  </div>
                  <div className="branches--container--title">
                    {t('ice.title')}
                  </div>
                </div>
              </div>
            </Link>
            <div className="spacer" />
          </div>
        </div>
      </div>

      <div id="contact" className="spacing" />
      <Footer />
    </div>
  );
};
export default DrinkLanding;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
